.menu {
    height: 26px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f0f0f0;
    margin: 0 -9px;

    body.dark & {
        border-color: #222;
    }

    &__logo {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 16px 0 9px;
        color: inherit;
    }

    &__link {
        font-size: 10px;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0 9px;
        color: inherit;
        opacity: .5;
        transition: 300ms opacity;

        &--active {
            opacity: 1;
            font-weight: 800;
            pointer-events: none;
        }

        &:hover {
            opacity: .8;
        }
    }

    &__profile {
        margin: 0 9px 0 16px;
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__right {
        display: flex;
        align-items: center;
    }
}

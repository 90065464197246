.media {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__menu {
    }

    &__top {
        height: 90px;
        flex: 0 0 90px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 20px;
        width: 100%;
    }

    &__left {
        display: flex;
        align-items: center;
        flex: 1;
        min-width: 0;
        padding-right: 20px;

        .prev-button {
        }
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__title {
        color: inherit;
        padding: 10px;
        flex: 1;
        min-width: 0;
    }

    &__info {
        display: flex;
        margin-bottom: 8px;
    }

    &__category {
        font-size: 11px;
        font-weight: 600;
        color: inherit;
        text-decoration: none;
    }

    &__author {
        font-size: 11px;
        font-weight: 300;
        color: inherit;
        margin-left: 8px;
        text-decoration: none;
    }

    &__text {
        font-size: 16px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__content {
        flex: 1 1;
        min-height: 0;
        height: 100%;
        width: 100%;
        background-color: #f0f0f0;
        position: relative;

        body.dark & {
            background-color: #111;
        }
    }

    &__bottom {
        position: fixed;
        bottom: 40px;
        height: 50px;
        pointer-events: none;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__nav {
        position: fixed;
        bottom: 40px;
        transition: 300ms opacity;

        .button {
            background-color: #fff;

            body.dark & {
                background-color: #000;
            }

            .icon {
                width: 18px;
                height: 18px;
            }
        }

        &--left {
            left: 50px;
        }

        &--right {
            right: 50px;
        }

        &--hidden {
            opacity: 0;
        }
    }

    &__loading {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: .5;

        .icon {
            width: 58px;
            height: 58px;
        }
    }
}

.not-found {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    opacity: .1;
    font-weight: 800;
}

.filter-popup {
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    transition: 300ms opacity;
    pointer-events: none;
    z-index: 9;
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 15px 20px;
    min-width: 385px;
    max-width: 385px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);

    body.dark & {
        background-color: #000;
        border-color: #444;
    }

    &--active {
        opacity: 1;
        pointer-events: all;
    }

    &__top {
        padding-top: 5px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 15px;
        font-weight: 700;
    }

    &__field {
        margin: 10px 0;

        .ant-select {
            width: 100%;
        }
    }

    &__delimiter {
        height: 1px;
        background-color: #000;
        opacity: .1;
        margin: 20px 0;

        body.dark & {
            background-color: #fff;
        }
    }

    &__switch {
        display: flex;
        align-items: center;
        padding: 3px 10px;

        .ant-switch {
            margin-right: 15px;
        }
    }

    &__reset {
        margin-bottom: 5px;

        .button {
            width: 100%;
        }
    }
}

@media (max-width: 574px) {
    .filter-popup {
        position: fixed;
        left: 20px;
        top: 64px;
        width: calc(100% - 40px);
        max-width: none;
        min-width: 0;
    }
}

.count {
    position: relative;

    &__content {}

    &__number {
        position: absolute;
        top: -7px;
        right: -7px;
        min-width: 19px;
        height: 19px;
        padding: 0 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        color: #fff;
        background-color: rgb(246, 64, 64);
        font-size: 11px;
        font-weight: 800;
        border-radius: 10px;
        pointer-events: none;
        transform: scale(0);
        opacity: 0;
        transition: 300ms transform, 300ms opacity;

        &--active {
            opacity: 1;
            transform: scale(1);
        }
    }
}

@keyframes focus-highlight {
    0%   { background-color: rgba(#ffe018, .2); }
    30%  { background-color: rgba(#ffe018, .2); }
    100% { background-color: transparent; }
}

@keyframes added-highlight {
    0%   { background-color: rgba(#1890ff, .2); }
    30%  { background-color: rgba(#1890ff, .2); }
    100% { background-color: transparent; }
}

@keyframes focus-highlight-dark {
    0%   { background-color: rgba(#ffe018, .2); }
    30%  { background-color: rgba(#ffe018, .2); }
    100% { background-color: transparent; }
}

@keyframes added-highlight-dark {
    0%   { background-color: rgba(#1890ff, .2); }
    30%  { background-color: rgba(#1890ff, .2); }
    100% { background-color: transparent; }
}
.post {
    display: block;
    text-decoration: none;
    //margin-bottom: 10px;
    padding: 10px 10px;

    &--focus-highlight {
        animation: focus-highlight 1200ms forwards;

        body.dark & {
            animation: focus-highlight-dark 1200ms forwards;
        }
    }

    &--added-highlight {
        animation: added-highlight 1200ms forwards;

        body.dark & {
            animation: added-highlight-dark 1200ms forwards;
        }
    }

    &__inner {
        position: relative;
        border-radius: 6px;
        overflow: hidden;
        display: block;
    }

    &__navigation {
        width: 100%;
        height: 100%;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;

        &--hidden {
            display: none;
        }
    }

    &__next,
    &__prev {
        width: 32px;
        height: 32px;
        border-radius: 32px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        position: absolute;
        z-index: 3;
        top: 50%;
        margin: 0;
        margin-top: -16px;
        appearance: none;
        border: none;
        padding: 0;
        pointer-events: all;
        cursor: pointer;
        transition: 300ms opacity;
        opacity: .5;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -6px;
            margin-left: -6px;
            width: 12px;
            height: 12px;
            background-color: transparent;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.swiper-button-disabled {
            opacity: .2 !important;
            cursor: default;
        }
    }

    &__inner:hover &__next,
    &__inner:hover &__prev {
        opacity: 1;
    }

    &__prev {
        left: 15px;

        &:after {
            background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20viewBox%3D%220%200%2026%2026%22%20width%3D%2226px%22%20height%3D%2226px%22%3E%3Cpath%20d%3D%22M%2010.59375%2013%20L%2019.179688%204.234375%20C%2019.5625%203.84375%2019.558594%203.21875%2019.171875%202.828125%20L%2017.636719%201.292969%20C%2017.242188%200.902344%2016.609375%200.902344%2016.21875%201.296875%20L%205.292969%2012.292969%20C%205.097656%2012.488281%205%2012.742188%205%2013%20C%205%2013.257813%205.097656%2013.511719%205.292969%2013.707031%20L%2016.21875%2024.703125%20C%2016.609375%2025.097656%2017.242188%2025.097656%2017.636719%2024.707031%20L%2019.171875%2023.171875%20C%2019.558594%2022.78125%2019.5625%2022.15625%2019.179688%2021.765625%20Z%22%2F%3E%3C%2Fsvg%3E');
        }
    }

    &__next {
        right: 15px;

        &:after {
            background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20viewBox%3D%220%200%2026%2026%22%20width%3D%2226px%22%20height%3D%2226px%22%3E%3Cpath%20d%3D%22M%2015.40625%2013%20L%206.820313%204.234375%20C%206.4375%203.84375%206.441406%203.21875%206.828125%202.828125%20L%208.363281%201.292969%20C%208.757813%200.902344%209.390625%200.902344%209.78125%201.296875%20L%2020.707031%2012.292969%20C%2020.902344%2012.488281%2021%2012.742188%2021%2013%20C%2021%2013.257813%2020.902344%2013.511719%2020.707031%2013.707031%20L%209.78125%2024.703125%20C%209.390625%2025.097656%208.757813%2025.097656%208.363281%2024.707031%20L%206.828125%2023.171875%20C%206.441406%2022.78125%206.4375%2022.15625%206.820313%2021.765625%20Z%22%2F%3E%3C%2Fsvg%3E');
        }
    }

    &__label {
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        position: absolute;
        font-size: 11px;
        line-height: 1;
        font-weight: 500;
        padding: 6px 10px 5px 10px;
        border-radius: 4px;
        z-index: 2;
    }

    &__resource {
        background-color: #fff;
        color: #000;
        position: absolute;
        font-size: 9px;
        text-transform: uppercase;
        line-height: 1;
        font-weight: 700;
        padding: 5px 8px;
        border-radius: 4px;
        z-index: 2;
        opacity: .2;
        transition: 300ms opacity;
    }

    &__inner:hover &__resource {
        opacity: .7;
    }

    &__media {
        position: relative;
        background-color: #e9e9e9;
        display: block;

        body.dark & {
            background-color: #222;
        }

        &--video .lazy-load-image-loaded:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            user-select: none;
            pointer-events: none;
            transform: translate(0);
        }

        &--video .lazy-load-image-loaded:after {
            content: '';
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -20px;
            margin-left: -20px;
            user-select: none;
            pointer-events: none;
            background-color: transparent;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            background-image: url('data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%3F%3E%3Csvg%20fill%3D%22%23ffffff%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20viewBox%3D%220%200%2030%2030%22%20width%3D%2230px%22%20height%3D%2230px%22%3E%20%20%20%20%3Cpath%20d%3D%22M%206%203%20A%201%201%200%200%200%205%204%20A%201%201%200%200%200%205%204.0039062%20L%205%2015%20L%205%2025.996094%20A%201%201%200%200%200%205%2026%20A%201%201%200%200%200%206%2027%20A%201%201%200%200%200%206.5800781%2026.8125%20L%206.5820312%2026.814453%20L%2026.416016%2015.908203%20A%201%201%200%200%200%2027%2015%20A%201%201%200%200%200%2026.388672%2014.078125%20L%206.5820312%203.1855469%20L%206.5800781%203.1855469%20A%201%201%200%200%200%206%203%20z%22%2F%3E%3C%2Fsvg%3E%0A');
        }
    }

    &__img {
        display: block;
        background-color: #e9e9e9;

        body.dark & {
            background-color: #222;
        }

        &--swiper {
            object-fit: contain;
            background-color: #000;

            body.dark & {
                background-color: #111;
            }
        }
    }

    &__title {
        color: inherit;
        padding: 10px;
    }

    &__info {
        display: flex;
        margin-bottom: 8px;
        min-height: 13px;
    }

    &__category {
        font-size: 11px;
        font-weight: 600;
        color: inherit;
        text-decoration: none;
        line-height: 13px;
    }

    &__author {
        font-size: 11px;
        font-weight: 300;
        color: inherit;
        margin-left: 8px;
        text-decoration: none;
        line-height: 13px;
    }

    &__text {
        font-size: 16px;
        font-weight: 600;
        min-height: 18px;
        line-height: 24px;
    }
}

@media (max-width: 374px) {
    .post {
        &__label {
            font-size: 9px;
        }
    }
}

.profile {
    margin: 0 9px 0 16px;
    font-size: 11px;
    display: flex;
    align-items: center;

    &__user {
        display: flex;
        align-items: center;
    }

    &__name {
        font-weight: 300;

        span {
            font-weight: 500;
        }
    }

    &__action {
        margin: 0;
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        margin-left: 22px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 10px;
        opacity: 0.5;
        cursor: pointer;
        color: #000;
        transition: 300ms opacity;

        body.dark & {
            color: #fff;
        }

        &:hover {
            opacity: 1;
        }
    }
}

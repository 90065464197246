.new-posts {
    position: fixed;
    left: 50%;
    top: 115px + 20px;
    margin: 0;
    margin-left: -60px;
    width: 120px;
    height: 36px;
    border-radius: 100px;
    appearance: none;
    padding: 0;
    border: none;
    background-color: #1890ff;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    //transform: translateY(-200px);
    transition: 300ms background-color, 300ms opacity;
    opacity: 0;
    z-index: 7;
    cursor: pointer;

    &:hover {
        background-color: lighten(#1890ff, 5%);
    }

    &--active {
        //transform: translateY(0);
        opacity: 1;
    }
}

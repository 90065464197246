.button {
    cursor: pointer;
    appearance: none;
    text-decoration: none;
    padding: 0 14px;
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    background-color: #f0f0f0;
    border: 1px solid #f0f0f0;
    height: 50px;
    min-width: 50px;
    border-radius: 8px;
    position: relative;

    body.dark & {
        color: #fff;
        background-color: #222;
        border-color: #444;
    }

    &--outline {
        background-color: transparent;
        border-color: #eee;

        body.dark & {
            background-color: transparent;
            border-color: #444;
        }
    }

    &--disabled {
        pointer-events: none;
        opacity: .4;
    }
}

@media (max-width: 767px) {
    .button {
        height: 36px;
        min-width: 36px;
        padding: 0 9px;
    }
}

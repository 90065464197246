
.picture {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 100%;
    min-height: 100%;

    &__wrapper {
        background-color: #e9e9e9;

        body.dark & {
            background-color: #222;
        }
    }

    &__img {
        display: block;
        margin: auto;
        user-select: none;
        background-color: #e9e9e9;

        body.dark & {
            background-color: #222;
        }
    }
}

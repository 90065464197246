.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -32px;
        margin-left: -32px;
        width: 64px;
        height: 64px;
        opacity: .5;
    }
}

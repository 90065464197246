.red-label {
    border: 1px solid rgb(246, 64, 64);
    color: rgb(246, 64, 64);
    font-size: 9px;
    font-weight: 600;
    padding: 1px 6px 0 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 17px;
    margin-top: -2px;
    margin-bottom: -2px;
}

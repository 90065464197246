.page {
    margin: 0 auto;
    width: 100%;
}

//@media (min-width: 374px) {
//  .page {
//    max-width: 374px;
//  }
//}

//@media (min-width: 574px) {
//  .page {
//    max-width: 374px;
//  }
//}

//@media (min-width: 767px) {
//  .page {
//    max-width: 574px;
//  }
//}

//@media (min-width: 991px) {
//  .page {
//    max-width: 767px;
//  }
//}

//@media (min-width: 1259px) {
//  .page {
//    max-width: 991px;
//  }
//}

//@media (min-width: 1599px) {
//  .page {
//    max-width: 1259px;
//  }
//}

//@media (min-width: 1919px) {
//  .page {
//    max-width: 1599px;
//  }
//}

//@media (min-width: 2359px) {
//  .page {
//    max-width: 1919px;
//  }
//}

.skeleton {
    display: block;
    background-color: #e9e9e9;
    background-image: linear-gradient(90deg, #e9e9e9 0px, #f0f0f0 var(--half), #e9e9e9 var(--full));

    body.dark & {
        background-color: #222;
        background-image: linear-gradient(90deg, #222222 0px, #2a2a2a var(--half), #222222 var(--full));
    }
}


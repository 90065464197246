.save-filter {
    appearance: none;
    background: none;
    padding: 5px 10px;
    margin: 0;
    border: none;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    &--active {
        color: rgb(234, 117, 117);
    }
}

.download {
    margin: 0;
    width: 120px;
    height: 36px;
    border-radius: 100px;
    appearance: none;
    padding: 0;
    border: none;
    background-color: #fff;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms opacity;
    opacity: 0.1;
    cursor: pointer;
    pointer-events: all;
    display: none; // tmp solution

    body.dark & {
        background-color: #000;
    }

    &:hover {
        opacity: 1;
    }
}

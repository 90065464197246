.gallery {
    height: 100%;

    &__swiper {
        height: 100%;
    }

    &__img {
        height: 100%;
        overflow: auto;
        position: relative;
    }

    &__next,
    &__prev {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        background-color: #fff;
        position: absolute;
        z-index: 3;
        top: 50%;
        margin: 0;
        margin-top: -20px;
        appearance: none;
        border: none;
        padding: 0;
        pointer-events: all;
        cursor: pointer;
        transition: 300ms opacity;
        opacity: .5;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -8px;
            margin-left: -8px;
            width: 16px;
            height: 16px;
            background-color: transparent;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }

        &.swiper-button-disabled {
            opacity: .2 !important;
        }
    }

    &:hover &__next,
    &:hover &__prev {
        opacity: 1;
    }

    &__prev {
        left: 30px;

        &:after {
            background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20viewBox%3D%220%200%2026%2026%22%20width%3D%2226px%22%20height%3D%2226px%22%3E%3Cpath%20d%3D%22M%2010.59375%2013%20L%2019.179688%204.234375%20C%2019.5625%203.84375%2019.558594%203.21875%2019.171875%202.828125%20L%2017.636719%201.292969%20C%2017.242188%200.902344%2016.609375%200.902344%2016.21875%201.296875%20L%205.292969%2012.292969%20C%205.097656%2012.488281%205%2012.742188%205%2013%20C%205%2013.257813%205.097656%2013.511719%205.292969%2013.707031%20L%2016.21875%2024.703125%20C%2016.609375%2025.097656%2017.242188%2025.097656%2017.636719%2024.707031%20L%2019.171875%2023.171875%20C%2019.558594%2022.78125%2019.5625%2022.15625%2019.179688%2021.765625%20Z%22%2F%3E%3C%2Fsvg%3E');
        }
    }

    &__next {
        right: 30px;

        &:after {
            background-image: url('data:image/svg+xml,%3Csvg%20fill%3D%22%23000000%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20viewBox%3D%220%200%2026%2026%22%20width%3D%2226px%22%20height%3D%2226px%22%3E%3Cpath%20d%3D%22M%2015.40625%2013%20L%206.820313%204.234375%20C%206.4375%203.84375%206.441406%203.21875%206.828125%202.828125%20L%208.363281%201.292969%20C%208.757813%200.902344%209.390625%200.902344%209.78125%201.296875%20L%2020.707031%2012.292969%20C%2020.902344%2012.488281%2021%2012.742188%2021%2013%20C%2021%2013.257813%2020.902344%2013.511719%2020.707031%2013.707031%20L%209.78125%2024.703125%20C%209.390625%2025.097656%208.757813%2025.097656%208.363281%2024.707031%20L%206.828125%2023.171875%20C%206.441406%2022.78125%206.4375%2022.15625%206.820313%2021.765625%20Z%22%2F%3E%3C%2Fsvg%3E');
        }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 40px;
        height: 50px;
        left: 0;
        width: 100%;
        z-index: 2;
        pointer-events: none;
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 40px;
        height: 50px;
        left: 0;
        width: 100%;
        z-index: 2;
        pointer-events: none;
    }

    &__pagination {
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        font-size: 13px;
        line-height: 1;
        font-weight: 500;
        padding: 8px 10px 7px 10px;
        border-radius: 4px;
        user-select: none;
        pointer-events: none;
    }
}

body, html {
  min-width: 320px;
  max-width: 100%;
  margin: 0;
  overflow-x: hidden;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2;
}

body {
  //transition: 300ms background-color, 300ms color;
}

body, input, textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #000;
}

body.dark {
  background-color: #000;
  color: #fff;

  ::-webkit-scrollbar-thumb {
    background: #fff;
  }

  .ant-input-affix-wrapper {
    color: rgba(255,255,255,.85);
    background-color: #000;
    border: 1px solid #333;
  }

  .ant-input {
    color: rgba(255,255,255,.85);
    background-color: #000;
  }

  .ant-input::placeholder {
    color: #555;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #000;
    border: 1px solid #333;
  }

  .ant-select {
    color: rgba(255,255,255,.85);
  }

  .ant-select-selection-placeholder {
    color: #555;
  }

  .ant-input-clear-icon, .anticon.ant-input-clear-icon {
    color: rgba(255,255,255,.25);
  }

  .ant-select-clear {
    color: rgba(255,255,255,.25);
    background: #000;
  }

  .ant-select-dropdown {
    color: rgba(255,255,255,.85);
    background-color: #000;
    //box-shadow: 0 3px 6px -4px rgb(0 0 0 / 24%), 0 6px 16px 0 rgb(0 0 0 / 16%), 0 9px 28px 8px rgb(0 0 0 / 10%);
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 48%), 0 6px 16px 0 rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 20%);
  }

  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #111;
  }

  .ant-select-item {
    color: rgba(255,255,255,.85);
  }
}

* {
  box-sizing: border-box;
  //outline: none;
}

video {
  outline: none;
}

.fixed-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 20;

  body.dark & {
    background-color: #000;
  }
}

a {
  transition: none;

  &:hover {
    color: inherit;
  }
}

.lazy-load-image-background {
  display: block !important;
  opacity: 0;
  transition: 300ms opacity;
}

.lazy-load-image-loaded {
  opacity: 1;
}

.theme-button {
    position: fixed;
    bottom: 40px;
    right: 50px;
    z-index: 10;

    body.dark & {
    }

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -13px;
        margin-left: -13px;
        width: 26px;
        height: 26px;
    }
}

.search {
    max-width: 720px;
    margin: 0 auto;

    &__field {
        margin-top: 40px;
        margin-bottom: 40px;
        position: relative;
    }

    &__clear {
        appearance: none;
        padding: 0;
        margin: 0;
        border: none;
        background: none;
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 0;
        cursor: pointer;

        .icon {
            width: 26px;
            height: 26px;
        }
    }

    &__input {
        border: none;
        background-color: transparent;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        appearance: none;
        outline: none;
        font-size: 32px;
        font-weight: 200;
        text-align: center;
        display: block;
        width: 100%;
        padding: 0;
        height: 60px;
        color: #000;
        transition: 300ms border-color;

        &:focus {
            border-color: rgba(0, 0, 0, 1);

            body.dark & {
                border-color: rgba(255, 255, 255, 1);
            }
        }

        body.dark & {
            border-color: rgba(255, 255, 255, 0.2);
            color: #fff;
        }

        &::placeholder {
            color: #aaa;

            body.dark & {
                color: #666;
            }
        }
    }

    &__results {
        padding-bottom: 10px;
    }

    &__result {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        border-radius: 8px;
        background-color: #f7f7f7;
        text-decoration: none;
        color: #000;
        padding: 15px 20px;

        body.dark & {
            color: #fff;
            background-color: #111;
        }
    }

    &__left {
        margin-right: 20px;
    }

    &__right {
        min-width: 0;
        flex: 1;
    }

    &__info {
        display: flex;
        margin-bottom: 8px;
        align-items: center;
    }

    &__name {
        font-size: 11px;
        font-weight: 600;
        line-height: 13px;
    }

    &__label {
        margin-left: 12px;
    }

    &__followers {
        font-size: 11px;
        font-weight: 300;
        margin-left: 12px;
        line-height: 13px;
    }

    &__desc {
        font-size: 15px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 20px;

        &--no-desc {
            opacity: .3;
            pointer-events: none;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 18px;
        }
    }
}

.video {
    width: 100%;
    height: 100%;
    position: relative;

    &--scroll {
        cursor: grab;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 100%;
        min-height: 100%;
    }

    &__right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        right: 50px;
        width: 50px;
        height: 100%;
        z-index: 2;
        pointer-events: none;
    }

    &__button {
        pointer-events: all;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }

        .button {
            background-color: #fff;

            body.dark & {
                background-color: #000;
            }

            .icon {
                width: 18px;
                height: 18px;
            }
        }
    }
}

.grid {
    padding: 0 10px 15px 10px;

    &__content {
        display: flex;
        align-items: flex-start;
        width: auto;
    }

    &__column {
        //padding: 0 10px;
        margin-top: -10px;
        background-clip: padding-box;
    }
}

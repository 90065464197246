.header {
    height: 26px + 90px;

    &__fixed {
        width: calc(100% - 2px);
        position: fixed;
        height: 26px + 90px;
        top: 0;
        left: 0;
        z-index: 8;

        &--active {
            background-color: #fff;

            body.dark & {
                background-color: #000;
            }
        }
    }

    &__top {
        .menu {
            padding-right: 18px;
        }
    }

    &__bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 90px;
        padding: 0 18px 0 20px;
    }

    &__center {
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        min-width: 0;
        max-width: 500px;
        height: 50px;
    }

    &__left {
        width: 115px;
        flex: 0 0 115px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__right {
        width: 115px;
        flex: 0 0 115px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__title {
        font-size: 22px;
        line-height: 1;
        font-weight: 700;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
        margin-bottom: 5px;
    }

    &__category {
        margin-top: 3px;
        font-size: 13px;
        line-height: 1;
        font-weight: 500;
        text-align: center;

        span {
            margin-left: 8px;
        }
    }
}

//@media (max-width: 991px) {
//    .header {
//        &__left {
//            display: none;
//        }
//
//        &__right {
//            display: none;
//        }
//    }
//}

@media (max-width: 767px) {
    .header {
        height: 26px + 70px;

        &__fixed {
            height: 26px + 70px;
        }

        &__bottom {
            height: 70px;
        }

        &__center {
            padding: 0 20px;
            height: 36px;
        }

        &__title {
            font-size: 15px;
            margin-bottom: 2px;
        }

        &__category {
            font-size: 11px;
        }

        &__left {
            width: 82px;
            flex: 0 0 82px;
        }

        &__right {
            width: 82px;
            flex: 0 0 82px;
        }
    }
}
